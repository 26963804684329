<template>
  <v-container class="pa-4">


    <v-row justify="space-between">
      <v-col cols="auto mt-4">
        <v-btn :disabled="isLoading" @click="goToLastStep()" text outlined x-large>Zurück</v-btn>
      </v-col>
      <v-col cols="auto">
        <div class="text-right mt-4">
          <v-btn :disabled="isLoading" :loading="isLoading" color="primary" @click="goToNextStep()" x-large>Fertig stellen
          </v-btn>
        </div>
      </v-col>
    </v-row>


    <v-form ref="form" v-model="isFormValid" lazy-validation class="mt-4">
      <v-row>



        <p-b-summary class="pa-3"/>

        <v-col cols="12">
          <p-b-signature/>
        </v-col>

        <v-col cols="12">
          <v-card rounded>
            <v-card-text class="pb-8">
              <v-checkbox :rules="notempty" v-model="isMoveToUs"
                          label="Sollte bereits ein Vertrag zur Lieferung bestehen, so beauftrage ich EasyVital GmbH mit der Kündigung des Vertrages meines bisherigen Anbieters mit dem heutigen Datum."/>

              <v-checkbox
                  :rules="dataProtection"
                  v-model="agreeToPrivacyPolicy"
                  value="success"
                  hide-details
                  class="mr-2">
                <template v-slot:label>
                 <span>
                  Ich habe die
                  <a @click.stop href="https://www.onlinepflegebox.de/datenschutzerklaerung/" target="_blank" rel="noopener"
                     class="privacy-link primary--text">Datenschutzerklärung</a>
                  gelesen und bin mit dieser einverstanden.
                </span>
                </template>
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <v-row justify="space-between">
      <v-col cols="auto mt-4">
        <v-btn :disabled="isLoading" @click="goToLastStep()" text outlined x-large>Zurück</v-btn>
      </v-col>
      <v-col cols="auto">
        <div class="text-right mt-4">
          <p-b-share/>
          <v-btn :disabled="isLoading" :loading="isLoading" color="primary" @click="goToNextStep()" x-large>Fertig stellen
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" v-if="!isOnline" class="justify-end text-end align-end">
        <v-icon color="error">mdi-wifi-remove</v-icon>
        <span class="ml-2 error--text">Kein Internet</span>
      </v-col>
    </v-row>

    <p-b-snackbar :msg="snackbarMessage"/>
  </v-container>
</template>


<script>
import moment from "moment/moment";
import {useOnline} from '@vueuse/core'
import {mapFields} from "vuex-map-fields";
import PBStepper from "@/components/PB-Stepper.vue";
import PBSnackbar from "@/components/PBSnackbar.vue";
import PBSummary from "@/components/PB-Summary.vue";
import PBShare from "@/components/PB-Share.vue";
import PBSignature from "@/components/PB-Signature.vue";
import {mapMutations} from "vuex";


export default {
  components: {PBSignature, PBShare, PBSummary, PBSnackbar, PBStepper},
  data() {
    return {
      isFormValid: false,
      now: moment().format("YYYY-MM-DD"),

      nameRules: [v => !!v || 'Bitte geben Sie Ihren Namen an.'],
      phoneRules: [v => !!v || 'Bitte geben Sie Ihre Telefonnummer an.'],
      emailRules: [
        v => !!v || 'E-Mail ist erforderlich',
        v => /.+@.+\..+/.test(v) || 'E-Mail muss gültig sein',
        // Hier können Sie die E-Mail-Domains hinzufügen, die Sie blockieren möchten
        v => !/(fake\.com|spam\.de|example\.org)$/.test(v) || 'Diese E-Mail-Domain ist nicht erlaubt'
      ],
      streetRules: [v => !!v || 'Bitte geben Sie Ihre Straße an.'],
      zipRules: [v => !!v || 'Bitte geben Sie Ihre Postleitzahl an.'],
      cityRules: [v => !!v || 'Bitte geben Sie Ihre Stadt an.'],
      dateRules: [v => !!v || 'Bitte geben Sie das Datum an.'],
      dataProtection: [v => !!v || 'Bitte bestätigen Sie die Datenschutzerklärung.'],
      notempty: [v => !!v || 'Bitte füllen Sie alle Pflichtfelder aus.'],
      moveToUsRules: [v => !!v || 'Bitte geben Sie ein Datum an.'],

      requestFor: 'self',

      agreeToPrivacyPolicy: false,

      snackbarMessage: {},
      isMoveToUs: false,
      moveToUsDate: "",
      isDatePickerOpen: false,

      // sending
      isLoading: false,
    };
  },

  computed: {
    ...mapFields([
      'customer',
      'additionalInformation',
      'customer.signature',
      'system.selectedProducts',
      'system.selectedBox',
      'system.requestChain'
    ]),

    isOnline() {
      return useOnline()?.value
    }
  },

  methods: {
    ...mapMutations(['resetStore']),

    async goToLastStep() {
      this.$router.push('/dateneingabe').then();
      this.$vuetify.goTo(0).then()
    },

    async sendPostRequest(output) {
      const url = 'https://www.docdorado.de/register';
      try {
        await fetch(url, {
          method: 'POST',
          body: JSON.stringify(output),
          headers: {
            'Content-Type': 'application/json'
          }
        });
        // Erfolg: Navigiere zur Dankesseite und lösche den Interval
        await this.$router.push({path: '/vielendank'});
        this.resetStore()
        this.isLoading = false;
      } catch (e) {
        console.log("Error: ", e);
        this.isLoading = false;
      }
    },

    checkOnlineAndSend(output) {
      this.isLoading = true;
      if (this.isOnline) {
        console.log("sending...")
        this.sendPostRequest(output);
      } else {
        this.requestChain.push(output)
        this.$router.push({path: '/offline'}).then(() => this.resetStore());
      }
    },

    async goToNextStep() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        this.snackbarMessage = {text: 'Bitte füllen Sie alle Pflichtfelder aus.', type: 'warning'};
        return;
      }

      if (!this.signature) {
        this.snackbarMessage = {text: 'Wir Benötigen ihre Unterschrift.', type: 'warning'};
        return;
      }

      if (!this.customer.moveToUsDate) {
        this.customer.moveToUsDate = moment(moment.now()).format("YYYY-MM-DD");
      }

      const output = {
        customer: this.customer,
        additionalInformation: this.additionalInformation,
        selectedProducts: this.selectedProducts,
        selectedBox: this.selectedBox,
      };

      await this.checkOnlineAndSend(output);
    },
  },
  watch: {
    isMoveToUs() {
      if (this.isMoveToUs) {
        this.moveToUsDate = moment(moment.now()).format("YYYY-MM-DD")
      }
    }
  }
}
</script>


<style scoped>
.privacy-link {
  cursor: pointer;
  text-decoration: underline; /* Fügt eine Unterstreichung hinzu, um klarzustellen, dass es sich um einen Link handelt. */
}
</style>
